<script setup>
    import { reactive, onMounted } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import LoadingAnimationComponent from '../components/LoadingAnimationComponent.vue'

    // Firebase関連のインポート
    import { signInWithEmailAndPassword, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue } from "firebase/database";
    import Firebase from "../firebase_settings/index.js"
    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()

    const reactiveObj = reactive({
        // ローディングアニメーション
        isLoading: false,
        // エラーメッセージ
        errorMessage: '',
        // 入力欄
        inputValueId: "",
        inputValuePassword: "",
    })

    // Home画面へ遷移
    const goToHome = () => {
        router.push('/')
    }
    //規約同意画面へ
    const goToAgree = () => {
        router.push('/agree')
    }
    const agreeCheck = (uid) => {
        return new Promise((resolve) => {
            const getData = databaseRef(database, `LoginUser/${uid}/Agree`)
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val() ? true : false);
                })
        })
    }
    const adminCheck = (uid) => {
        return new Promise((resolve) => {
            const getData = databaseRef(database, `LoginUser/${uid}/Administorator`)
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val() ? true : false);
                })
        })
    }
    // ログアウト処理
    const logOut = () => {
        // ユーザー情報とイベント内容をログに記録
        signOut(auth).then(() => {
            // Sign-out successful.
            router.push('/login')
            //window.location.reload()
            //alert('ログアウトしました')
        }).catch((error) => {
            // An error happened.
            //console.log('ログアウトエラー: error ->'+error)
            //alert('ログアウト処理でエラーが発生しました')
        });
    }
    // ログイン
    const logIn = () => {
        // IDとパスワードの未入力チェック
        if(reactiveObj.inputValueId === undefined || reactiveObj.inputValueId === "" || reactiveObj.inputValuePassword === undefined || reactiveObj.inputValuePassword === "") {
            reactiveObj.errorMessage = 'IDまたはパスワードが未入力です'
            return
        }
        // エラーメッセージの消去
        reactiveObj.errorMessage = ""
        // ローディングアニメーション
        reactiveObj.isLoading = true
        // id,passを取得
        const mId = reactiveObj.inputValueId
        const mPass = reactiveObj.inputValuePassword
        // 連続クリックを防ぐためにパスワード欄を空欄にする
        reactiveObj.inputValuePassword = ""
        // サインインメソッド
        signInWithEmailAndPassword(auth, mId, mPass)
            // サインイン成功時
            .then(async (userCredential) => {
                if(await adminCheck(userCredential.user.uid)){
                    if(await agreeCheck(userCredential.user.uid)){
                        goToHome()
                    } else {
                        goToAgree()
                    }
                } else {
                    JudgeErrorCode("not admin user")
                    logOut()
                }
            })
            // サインイン失敗時
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                //console.log('ログインエラー: errorCode -> '+errorCode+', errorMessage -> '+errorMessage)
                JudgeErrorCode(errorCode)
                // ローディングアニメーション
                reactiveObj.isLoading = false
            });
    }
    // FirebaseAuthから受け取ったエラーコードを判定しエラーメッセージを表示する
    const JudgeErrorCode = (mError) => {
        const errorCode = String(mError)
        if(errorCode === 'auth/wrong-password' || errorCode === 'auth/invalid-email' || errorCode === 'auth/user-not-found' || errorCode === 'auth/invalid-credential') {
            reactiveObj.errorMessage = "ログインに失敗しました。IDまたはパスワードが間違っています"
        } else if( errorCode === "not admin user") {
            reactiveObj.isLoading = false
            reactiveObj.errorMessage = "管理者ユーザーでないため、ログインできませんでした。"
        } else {
            reactiveObj.errorMessage = "ログインに失敗しました。予期せぬエラーが発生しました。"
        }
    }
</script>
<template>
    <body>
        <header>
            <div class="title">ProoFace 管理WEB</div>
        </header>

        <main>
            <div class="inputContainer">
                <p>管理者メールアドレス</p>
                <input type="email" v-model="reactiveObj.inputValueId">
                <p></p>
            </div>

            <div class="inputContainer">
                <p>管理者パスワード</p>
                <input type="password" v-model="reactiveObj.inputValuePassword">
                <p></p>
            </div>

            <div class="message">
                <p class="red">{{ reactiveObj.errorMessage }}&nbsp;</p>
            </div>

            <div>
                <button class="btnStandard" type="submit" @click="logIn">ログイン</button>
            </div>
            <!-- ローディングアニメーション -->
            <div class="loadingAnimationContainer">
                <div class="loadingAnimation" v-if="reactiveObj.isLoading">
                    <LoadingAnimationComponent></LoadingAnimationComponent>
                </div>
            </div>
        </main>
    </body>
</template>

<style scoped>
    header {
        height: 120px;
    }
    header .title {
        font-size: 40px;
        padding: 20px 0 0;
    }
    main {
        padding: 30px auto;
    }
    .inputContainer {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .inputContainer input {
        width: 400px;
    }
    .inputContainer p {
        width: 200px;
        margin: 30px 0 30px 0;
    }

    .loadingAnimationContainer {
        height: 15px;
        margin-top: 5%;
    }

</style>