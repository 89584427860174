<script setup>
    import { onMounted } from 'vue'
    const props = defineProps({
        modalMessage: {
            type: String,
            require: true
        },
    });
    const emit = defineEmits(['executeMethod']);
    const exitModal = (editFlag) => {
        emit('executeMethod', editFlag);
    }
    onMounted(() => {
        if(props.modalMessage) {
            document.getElementById("checkMessage").innerHTML = props.modalMessage
        }
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal" >
            <p id="checkMessage"></p>
            <button id="commonButton" @click="exitModal(true)">OK</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 30%;
        height: 50%;
        top: 50%;
        left: 50%;
    }
    #checkMessage {
        margin: 10% 10% 10% 10%;
        font-size: 150%;
    }
    #commonButton {
        width: 30%;
        height: 15%;
        top: 75%;
        left: 50%;
        position: absolute;
    }
</style>