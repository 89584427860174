
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import HeaderComponent from "../components/HeaderComponent.vue"
    import EditTeminalComponent from "../components/EditTeminalComponent.vue"
    import AddTeminalComponent from "../components/AddTeminalComponent.vue"
    import NavigationBar from "../components/NavigationBar.vue"
    import PaginateComponent from 'vuejs-paginate-next'
    import {onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue, update, push, child } from "firebase/database";
    import Firebase from "../firebase_settings/index.js"
    import {formatToTimeZone} from 'date-fns-timezone';

    const FORMAT = 'YYYY/MM/DD HH:mm:ss';
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;

    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //ドア情報一覧
        terminalObjects: [],
        //子コンポーネントに渡すようのドア情報
        terminalObject: {},
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //端末追加様のモーダル
        addModalFlag: false
    })
    //1ページに表示する件数
    const perPage = 10;
    //firebaseからログインユーザーの情報を取得
    const getTerminals = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const getData = databaseRef(database, `LoginUser/${user.uid}`)
                onValue(getData, (snapshot) => {
                    reactiveObj.corporateId = snapshot.val()["CorporateId"]

                    const terminalsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals`)
                    //firebaseからドア情報を取得する
                    onValue(terminalsRef, (snapshot) => {
                        reactiveObj.terminalObjects = []
                        const terminals = (snapshot.val())
                        for (let key in terminals) {
                            reactiveObj.terminalObjects.push({
                                'modal': false,
                                'terminalId': key,
                                'terminal': terminals[key]['TerminalName'],
                                'location': terminals[key]['Location'],
                                'locktime': terminals[key]['LockTime'],
                                'terminalType': terminals[key]['TerminalType'],
                                'connection': terminals[key]['Connection'],
                                'deviceType': terminals[key]['DeviceType'],
                                'displayLatest': terminals[key]["DisplayLatestUpdated"] ? formatToTimeZone(terminals[key]["DisplayLatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO }) : "",
                                'settingLatest': formatToTimeZone(terminals[key]["LatestUpdated"], FORMAT, { timeZone: TIME_ZONE_TOKYO })
                            })
                        }
                    })
                    reactiveObj.renderKey++
                })
            } else {
            }
        })
    }
    //n個目のモーダルを表示する
    const showEditModal = (data, key) => {
        reactiveObj.terminalObjects[key]['modal'] = true
        reactiveObj.terminalObject = data
    }
    //モーダルを閉じる
    const closeEditModal = (editFlag, terminalIndex, editTerminalObject) => {
        //n個目のモーダルを閉じる
        reactiveObj.terminalObjects[terminalIndex]['modal'] = false
        //更新対象の判別
        if(editFlag) {
            const terminalData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/Terminals/${editTerminalObject['terminalId']}`);
            update(terminalData, editTerminalObject['editTerminalInfo']);
        }
    }
    const showAddModal = () => {
        reactiveObj.addModalFlag= true
    }
    const closeAddModal = (editFlag, newTeminalObj) => {
        reactiveObj.addModalFlag= false
        if(editFlag){
            const locationMap = {
                "内側": "退室",
                "外側": "入室"
            }
            for(let i = 0; i < (newTeminalObj.terminalType == 'parent' ? 2 : 1); i++){
                const terminalPath = `CorporateId/${reactiveObj.corporateId}/Terminals`
                const newTeminalKey = push(child(databaseRef(database), terminalPath)).key;
                const data = {
                    "AllowViewAccount": [],
                    "Connection": false,
                    "DeviceType": "Sesame",
                    "LatestUpdated": Date.now(),
                    "DisplayLatestUpdated": 0,
                    "Location": i == 0 ? newTeminalObj.location : newTeminalObj.location == "内側" ? "外側" : "内側",
                    "LockTime": Number(newTeminalObj.locktime),
                    "PermittedGroupId": [
                        "All"
                    ],
                    "TerminalName": newTeminalObj.terminal,
                    "TerminalType": i == 0 ? newTeminalObj.terminalType : 'child',
                    "EntryExit": i == 0 ? locationMap[newTeminalObj.location] : locationMap[newTeminalObj.location == "内側" ? "外側" : "内側"]
                }
                update(databaseRef(database, `${terminalPath}/${newTeminalKey}`), data)
            }
        }
    }

    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.terminalObjects.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.terminalObjects.length / perPage) ? Math.ceil(reactiveObj.terminalObjects.length / perPage) : 1
    })
    onMounted(() => {
        getTerminals()
    })
</script>
<template>
    <body>
        <!-- コメントアウト用 -->
        <HeaderComponent></HeaderComponent>
        <NavigationBar></NavigationBar>
        <main>
            <div id="terminalStatus" :key="`${reactiveObj.renderKey}-terminalStatus`">
                <div class="titleArea">
                    <p class="title">端末状況</p>
                    <button id="addButton" @click="showAddModal">端末追加</button>
                    <AddTeminalComponent v-show="reactiveObj.addModalFlag" @executeMethod="closeAddModal"></AddTeminalComponent>
                </div>
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th id="terminal" class="leftSideTh">端末名</th>
                            <th id="parent">親子設定</th>
                            <th id="edit">編集</th>
                            <th id="displayLatest" class="leftSideTh">最終更新日時</th>
                            <!--<th id="settingLatest">最終更新日時</th>-->
                        </tr>
                        <tr v-for="(data, key) in getItems" :key="key">
                            <td class="leftSideTd">{{ data.terminal }}({{ data.location }})</td>
                            <td>{{ data.terminalType == 'parent'? '親機': data.terminalType == 'child'? '子機' : '単独' }}</td>
                            <td><button type="button" class="editButton" @click="showEditModal(data, (reactiveObj.currentPage-1)*perPage+key)"><img src="../assets/pencil.png" id="pencilIcon"></button>
                                <EditTeminalComponent :terminalObject="reactiveObj.terminalObject" :terminalIndex="(reactiveObj.currentPage-1)*perPage+key" v-show="data['modal']" @executeMethod="closeEditModal"></EditTeminalComponent>
                            </td>
                            <td class="leftSideTh">{{ data.displayLatest  }}</td>
                            <!--<td >{{ data.settingLatest  }}</td>-->
                        </tr>
                    </tbody>
                </table>

                <div class="paginationArea">
                    <PaginateComponent
                        :page-count="getPageCount"
                        :click-handler="clickCallback"
                        :page-range="5"
                        :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                        :next-text="reactiveObj.currentPage < parseInt(reactiveObj.terminalObjects.length / 10) + 1 ? '>' : ''"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </PaginateComponent>
                </div>
            </div>
        </main>
    </body>
</template>

<style scoped>
    #terminalStatus{
        padding: 0 5% 0 25%;
        background-color: #e7e6e6;
    }
    .titleArea {
        height: 45px;
    }
    #addButton {
        position: relative;
        display: flex;
        margin-left: auto;
        top: -40px;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
    .commonTable #terminal{
        width: 50%;
    }
    .commonTable #edit{
        width: 10%;
    }
    .commonTable #parrent{
        width: 10%;
    }
    .commonTable #displayLatest{
        width: 20%;
    }
    .editButton {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
    }
    #pencilIcon {
        width: 30px;
    }
    #naviTerminalStatusView {
        background-color: #32a4ea;
        color: #F5F5F5;
    }
</style>
