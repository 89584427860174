
<script setup>
    import HeaderComponent from "../components/HeaderComponent.vue"
    import { useRouter } from 'vue-router'
    import { ref as databaseRef, update } from "firebase/database";
    import Firebase from "../firebase_settings/index.js"

    const auth = Firebase.auth
    const database = Firebase.database;
    const router = useRouter()


    const agreeTerm = () => {
        const getData = databaseRef(database, `LoginUser/${auth.currentUser.uid}`);
        update(getData, {'Agree': true});
        router.push('/')
    }
</script>
<template>
    <body>
        <!-- コメントアウト用 -->
        <HeaderComponent></HeaderComponent>
        <main>
            <div class="agreeTermArea">
                <p id="title">ProoFace by SESAME 利用規約</p>
            </div>
            <div id="agreeTerm">
                <p>あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                   あああああああああああああああああああああああああああああああああああああああああああああああああああああ
                </p>
                <button id="agreeButton" @click="agreeTerm">利用規約に同意する</button>
            </div>
        </main>
    </body>
</template>

<style scoped>
    #title {
        font-size: 150%;
        margin: 0 0 50px 0;
    }
    #agreeTerm {
        margin: 0 20% 0 20%;
    }
    #agreeButton {
        margin: 50px 0 0 0;
        border: solid 3px #7CD5F5;
        background: #7CD5F5;
    }
</style>
