<script setup>
    import { reactive } from 'vue'
    import { useRoute } from 'vue-router'
    import { updatePassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
    import { ref as databaseRef, onValue, query, equalTo, orderByChild } from "firebase/database";
    import Firebase from "../firebase_settings/index.js"
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import axios from 'axios';

    const auth = Firebase.auth
    const database = Firebase.database;
    const route = useRoute()
    const reactiveObj = reactive({
        //現在のメールアドレス
        email: "",
        //現在のパスワード
        password: "",
        //新しいパスワード
        newPassword1: "",
        //新しいパスワード（確認）
        newPassword2: "",
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //モーダルに関する特殊操作に用いる
        optionFlag: false,
        //
        loadingFlag: false
    })
    // ログアウト処理
    const logOut = () => {
        // ユーザー情報とイベント内容をログに記録
        signOut(auth).then(() => {
            // Sign-out successful.
            //window.location.reload()
        }).catch((error) => {
            // An error happened.
            //console.log('ログアウトエラー: error ->'+error)
            alert('ログアウト処理でエラーが発生しました')
        });
    }
    const pushEmail = (email) => {
        return new Promise((resolve) => {
            axios({
                baseURL: "https://asia-northeast1-prooface-smartlock.cloudfunctions.net/pushMail",
                method: 'post',
                params: {
                    email: email,
                }
            })
            .then((res)=>{
                //console.log(res)
                resolve(true)
            })
            .catch((e)=>{
                //resolve(false)
            })
        })
    }
    const checkCorporate = () => {
        return new Promise((resolve) => {
			const getData = databaseRef(database, `CorporateId/${route.query.CorporateId}/User`);
            const checkData = query(getData, orderByChild("Email"), equalTo(reactiveObj.email))
            onValue(checkData, (snapshot) => {
                resolve(snapshot.val()? true : false)
            })
		})
    }
    const showDefalutModal = (message, agreeFlag = false) => {
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2 || agreeFlag){
            //モーダルを表示
            reactiveModalObj.modalFlag= true
            //モーダル上の表示する文面
            reactiveModalObj.modalMessage = message ? message : `入力した内容でパスワードを変更します。<br><br>よろしいですか？`
            //モーダル部分を再描画
            reactiveModalObj.modalRenderkey++
        }
        else {
            alert('入力された新しいパスワードが一致しません')
        }
    }
    const closeDefalutModal = (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag && !reactiveModalObj.optionFlag) {
            reactiveModalObj.loadingFlag = true
            //パスワードの確認の為再度ログインさせる&長期ログインしていないユーザーに対してトークンの更新
            signInWithEmailAndPassword(auth, reactiveObj.email, reactiveObj.password)
            // サインイン成功時
            .then(async (userCredential) => {
                if(!await checkCorporate()) {
                    alert(`企業コードまたはURLが違います。\n再度、新規登録時のメールリンクからお試しください。`)
                    reactiveModalObj.loadingFlag = false
                }
                else {
                    const user = userCredential.user;
                    const newPassword = reactiveObj.newPassword1
                    //処理中
                    //document.querySelector("body").inert = true
                    updatePassword(user, newPassword).then(() => {
                        reactiveModalObj.loadingFlag = false
                        showDefalutModal(`パスワードを変更しました。`, true)
                        pushEmail(reactiveObj.email)
                        reactiveModalObj.optionFlag = true
                        reactiveObj.email= ""
                        reactiveObj.password = ""
                        reactiveObj.newPassword1 = ""
                        reactiveObj.newPassword2 = ""
                    }).catch((error) => {
                        reactiveModalObj.loadingFlag = false
                        alert('パスワード更新ができませんでした')
                        //console.log(error)
                    });
                }
                logOut()
            })
            // サインイン失敗時
            .catch((error) => {
                reactiveModalObj.loadingFlag = false
                const errorCode = error.code;
                const errorMessage = error.message;
                //console.log('ログインエラー: errorCode -> '+errorCode+', errorMessage -> '+errorMessage)
                //console.log(errorMessage)
                alert('現在のパスワードもしくはメールアドレスが誤っています')
                //メールアドレスが異なるとき→auth/invalid-credential
            });
        }
    }
</script>

<template>
    <body>
        <main>
            <link rel="stylesheet" href="./togglePassword.css">
            <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
            <div id="loadingDisplay" class="loading" v-show="reactiveModalObj.loadingFlag">
                <div class="load-text load-blink">処理中...</div>
            </div>
            <div class="userPassword">
                <p class="title">利用者のパスワード変更</p>
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　登録メールアドレス</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="text" class="hideText" v-model="reactiveObj.email">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　現在のパスワード</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.password">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　新しいパスワード</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　新しいパスワード（確認）</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="updateArea notButtom"><button type="button" id="commonButton" @click="showDefalutModal()">変更する</button></td>
                        </tr>
                        <tr>
                            <td class="notTop"><router-link :to="{name: 'userpasswordreset', query: {CorporateId: route.query.CorporateId}}">パスワードを忘れた方はこちら</router-link></td>
                        </tr>
                    </tbody>
                </table>
                <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefalutModal"></DefaultModalComponent>
            </div>
        </main>
    </body>
</template>

<style scoped>
    .userPassword{
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child{
        border-radius: 20px 20px 0 0;
    }
</style>