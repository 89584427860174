<script setup>
    import { computed, reactive } from 'vue'
    const props = defineProps({
        terminalObject: {
            type: Object,
            require: true
        },
        terminalIndex: {
            type: Number,
            require: true
        }
    });
    const emit = defineEmits(['executeMethod']);
    const reactiveObj = reactive({
        //ドア（端末名）
        terminal: '',
        //設置先
        location: '',
        //親機/子機/単独
        terminalType: '',
        //施錠までの時間
        locktime: '',
        //接続状態(一時的に実装)
        connection: true
    })
    //変更するドアの情報を格納するオブジェクト
    let editTerminalObject = {}
    //施錠時間のマッピング
    const lockTimeList = {
        'none': 'none',
        '5': '5秒',
        '7': '7秒',
        '10' : '10秒',
        '15' : '15秒',
        '20' : '20秒',
        '30' : '30秒',
        '0': '自動で施錠しない',
        '-1': 'オープンセンサーを使用'
    }
    const exitModal = (editFlag) => {
        document.getElementById('commonButton-'+props.terminalIndex).disabled = true;
        if(!editFlag) {
            emit('executeMethod', false, props.terminalIndex);
        }
        else {
            const modalTerminal = document.getElementById('modalTerminal-'+props.terminalIndex).value;
            const modalLocation = document.getElementById('modalLocation-'+props.terminalIndex).value
            const modalTerminalType = document.getElementById('modalTerminalType-'+props.terminalIndex).value
            const editConnection = document.getElementById('editConnection-'+props.terminalIndex).value
            //lockTimeListから逆引きして抽出
            const modalLocktime = Number((Object.keys(lockTimeList).filter((key) => {
                return lockTimeList[key] == document.getElementById('modalLocktime-'+props.terminalIndex).value
            }))[0]);
            editTerminalObject = {
                'terminalId': props.terminalObject['terminalId'],
                'editTerminalInfo': {
                    'TerminalName': modalTerminal,
                    'Location': modalLocation,
                    'TerminalType': modalTerminalType,
                    'LockTime': modalLocktime,
                    'LatestUpdated': Date.now(),
                    "EntryExit": modalLocation == "内側" ? "退室" : "入室",
                    "Connection": editConnection == "false" ? false : true
                }
            }
            //変更される情報が１つ以上あればモダールを閉じて親コンポーネントに渡す
            emit('executeMethod', true, props.terminalIndex, editTerminalObject);
        }
        return
    }
    const inputSomeData = (data, type) => {
        if(props.terminalObject[type] != data && lockTimeList[String(props.terminalObject['locktime'])] != data){
            document.getElementById('commonButton-'+props.terminalIndex).disabled = false;
        }
    }
    const getTerminalName = computed({
        get: () => {
            return props.terminalObject['terminal']
        },
        set: (value) => {
            reactiveObj.terminal = value
            document.getElementById('modalTerminal-'+props.terminalIndex).value = value
            inputSomeData(value, 'terminal')
        }
    })
    const getTerminalType = computed({
        get: () => {
            return props.terminalObject['terminalType']
        },
        set: (value) => {
            reactiveObj.terminalType = value
            document.getElementById('modalTerminalType-'+props.terminalIndex).value = value
            inputSomeData(value, 'terminalType')
        }
    })
    const getLocation = computed({
        get: () => {
            return props.terminalObject['location']
        },
        set: (value) => {
            reactiveObj.location = value
            document.getElementById('modalLocation-'+props.terminalIndex).value = value
            inputSomeData(value, 'location')
        }
    })
    const getLocktime = computed({
        get: () => {
            return lockTimeList[String(props.terminalObject['locktime'])]
        },
        set: (value) => {
            reactiveObj.locktime = value
            document.getElementById('modalLocktime-'+props.terminalIndex).value = value
            inputSomeData(value, 'locktime')
        }
    })
    const getConnection = computed({
        get: () => {
            return props.terminalObject['connection']
        },
        set: (value) => {
            reactiveObj.connection = value
            document.getElementById('editConnection-'+props.terminalIndex).value = value
            inputSomeData(value, 'connection')
        }
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">端末情報の変更</p>
            <div class="modalInfo">
                <p>端末名</p>
                <input :id="`modalTerminal-${terminalIndex}`" class="modalTerminal" v-model="getTerminalName">
            </div>
            <div class="modalInfo">
                <p>設置先</p>
                <select :id="`modalLocation-${terminalIndex}`" class="modalLocation" v-model="getLocation">
                    <option value="外側">外側</option>
                    <option value="内側">内側</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>親子設定</p>
                <select :id="`modalTerminalType-${terminalIndex}`" class="modalTerminalType" v-model="getTerminalType">
                    <option value="parent">親機</option>
                    <option value="child">子機</option>
                    <option value="single">単独</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>施錠までの時間</p>
                <select :id="`modalLocktime-${terminalIndex}`" class="modalLocktime" v-model="getLocktime">
                    <option value="5秒">5秒</option>
                    <option value="7秒">7秒</option>
                    <option value="10秒">10秒</option>
                    <option value="15秒">15秒</option>
                    <option value="20秒">20秒</option>
                    <option value="30秒">30秒</option>
                    <option value="自動で施錠しない">自動で施錠しない</option>
                    <option value="オープンセンサーを使用">オープンセンサーを使用</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>接続設定</p>
                <select :id="`editConnection-${terminalIndex}`" class="editConnection" v-model="getConnection">
                    <option value="false">接続を解除する</option>
                    <option value="true">接続（を維持）する</option>
                </select>
            </div>
            <button :id="`commonButton-${terminalIndex}`" @click="exitModal(true)" disabled>変更する</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 60%;
        height: 80%;
        top: 50%;
        left: 50%;
    }
    button[id^="commonButton"] {
        width: 30%;
        height: 15%;
        top: 10%;
        left: 50%;
    }
</style>