
<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import { formatToTimeZone } from 'date-fns-timezone';
    import HeaderComponent from '../components/HeaderComponent.vue'
    import NavigationBar from '../components/NavigationBar.vue'
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import axios from 'axios';

    import { onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue, update, push, child } from "firebase/database";
    import { ref as storageRef, uploadBytes } from "firebase/storage";
    import Firebase from "../firebase_settings/index.js"

    const DATEFORMAT = 'YYYYMMDD'
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;
    const storage = Firebase.storage;

    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //登録者一覧
        registrants: [],
        //更新対象の登録者
        newRegistrants: [],
        //アップロード画像
        newImages: [],
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //姓名フラグ
        UseNameFlag1: false,
        //セイメイフラグ
        UseNameFlag2: false,
        //ローディング
        loadingFlag: false,
        //登録処理結果
        outputData: ""
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0
    })
    //1ページに表示する件数
    const perPage = 10;

    const getCorporateId = () => {
        return new Promise((resolve) => {
            //ログインチェック
            onAuthStateChanged(auth, (user) => {
                const getData = databaseRef(database, `LoginUser/${user.uid}`);
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val()["CorporateId"] ? snapshot.val()["CorporateId"] : "");
                })
            })
		})
    }
    //firebaseから登録者一覧を取得
    const getRegstrants = () => {
        if(reactiveObj.corporateId) {
            const regstrantsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User`);
            //firebaseから登録者を取得する
            onValue(regstrantsRef, (snapshot) => {
                const Regstrants = (snapshot.val());
                reactiveObj.registrants = [];
                for(let key in Regstrants) {
                    let faces = ''
                    Regstrants[key]['FaceUrl'].forEach(element => {return faces+=element+'\n'})
                    let group = ''
                    Regstrants[key]['UserGroup'].forEach(element => {return group+=element+'\n'})
                    reactiveObj.registrants.push({
                        userId: key,
                        Id: Regstrants[key]['Id'],
                        FirstName1: Regstrants[key]['FirstName1'],
                        LastName1: Regstrants[key]['LastName1'],
                        FirstName2: Regstrants[key]['FirstName2'],
                        LastName2: Regstrants[key]['LastName2'],
                        Email: Regstrants[key]['Email'],
                        UserGroup: Regstrants[key]['UserGroup'],
                        group: group,
                        FaceUrl: Regstrants[key]['FaceUrl'],
                        face: Regstrants[key]['FaceUrl'][0].length > 0 ?  "登録済" : "未登録",
                        LatestUpdated: Regstrants[key]['LatestUpdated']
                    })
                }
                //ID順に並べ替え
                reactiveObj.registrants = reactiveObj.registrants.sort((element, nextElement) => {
                    return (element['LatestUpdated'] > nextElement['LatestUpdated']) ? -1 : 1;
                })
            })
            reactiveObj.renderKey++
        }
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //CSVファイルアップロード処理
    const fileUpload = (e) => {
        let fileChecker = true
        const file = e.target.files[0];
        //拡張子でバリデーション
        const extentionRegExp = new RegExp(/.*\.csv$/, 'i')
        if(!extentionRegExp.test(file['name'])) {
            alert('CSVファイルをアップロードしてください')
            return;
        }
        const reader = new FileReader();
        const loadFunc = () => {
            //改行毎に配列に分解
            const lines = reader.result.split(/\r|\n|\r\n/).slice(1);
            lines.forEach(element => {
                const data = element.split(",");
                let newData = {}
                //空白分はスキップ
                if (data.length == 1) {
                    return;
                }
                const extentionRegExp = new RegExp(/.*,.*,.*,.*,.*,[a-z\d][\w.-]*@[\w.-]+\.[a-z\d]+,.*,(.*\.jpg|.*\.jpeg|.*\.png|.*\.JPG|.*\.JPEG|.*\.PNG)/, 'i')
                if(!extentionRegExp.test(element)) {
                    fileChecker = false
                }
                //項目が7つ（ID、姓、名、セイ、メイ、メールアドレス、画像URL）の時
                if(data.length >= 8) {
                    newData = {
                        'Id': data[0],
                        'LastName1': data[1],
                        'FirstName1': data[2],
                        'LastName2': data[3],
                        'FirstName2': data[4],
                        'Email': data[5],
                        'UserGroup': data[6],
                        'FaceUrl': data[7],
                    }
                };
                reactiveObj.newRegistrants.push(newData);
                //更新対象の登録者がいる場合、ラベル名を変更
                if(reactiveObj.newRegistrants.length > 0) {
                    document.getElementById("csvLabel").innerHTML = '選択済';
                }
            });
        };
        reader.onload = loadFunc;
        //文字化け防止
        reader.readAsText(file, 'Shift_JIS')
        if(!fileChecker) {
            alert('正しい形式のCSVファイルをアップロードしてください')
            reactiveObj.newRegistrants = []
            document.getElementById("csvLabel").innerHTML = 'CSVファイル参照';
        }
    }
    //画像アップロード処理
    const imageUpload = (e) => {
        try {
            const files = Array.from(e.target.files)
            //対応拡張子を.jpeg .jpg .pngにバリデーション
            const regexp = new RegExp(/.*\.jpg$|.*\.jpeg$|.*\.png$/, 'i')
            //拡張子チェッカー
            let checkFlag = false
            for(let index in files) {
                //10MBかつ対応している拡張子の画像のみを保持する
                if(regexp.test(files[index]['name']) && files[index]['size'] <= 10485760) {
                    reactiveObj.newImages.push(files[index])
                }
                else {
                    checkFlag = true
                }
            }
            if(checkFlag) {
                alert('対応している画像は10MBかつ以下の拡張子となり、ファイルサイズまたは拡張子が異なる画像は除外されます\n.jpg .jpeg .png')
            }
            //アップロード可能な画像がある場合、ラベル名を変更
            if(reactiveObj.newImages.length > 0) {
                document.getElementById("imageLabel").innerHTML = '選択済';
            }
        }
        catch {
            alert('読み取り中にエラーが発生しました。ページを更新して再度やり直してください')
            document.getElementById("imageLabel").innerHTML = '画像ファイル参照';
            reactiveObj.newImages = []
        }
    }
    //アップロード処理
    const uploadExec = () => {
        reactiveObj.loadingFlag = true
        //更新対象が0かつもしくは更新画像がない場合
        if(reactiveObj.newRegistrants.length == 0 && reactiveObj.newImages.length == 0) {
            alert('先に「CSVファイル参照」もしくは「画像ファイル参照」より\n更新するファイルをアップロードしてください')
        }
        else {//更新対象が1人以上もしくは更新画像がある場合
            //firebaseの更新処理を行う
            if(reactiveObj.newRegistrants.length != 0) {
                userUpdate()
            }
            if(reactiveObj.newImages.length != 0){
                imageUpdate()
            }
            //ファイル選択が再発火できるようにリセットを行う
            resetExec();
        }
        reactiveObj.renderKey++;
    }
    const outputCSVData = (csvData) => {
        const filename = `result_${formatToTimeZone(Date.now(), DATEFORMAT, { timeZone: TIME_ZONE_TOKYO })}.csv`
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, csvData], { type: "text/csv" })
        //リンク先にダウンロード用リンクを指定する
        const link = document.createElement('a')
        link.download = filename
        link.href = URL.createObjectURL(blob)
        link.click()

        //createObjectURLで作成したオブジェクトURLを開放する
        URL.revokeObjectURL(link.href)
    }
    const execFunction = (baseURL, params) => {
        axios({
            baseURL: baseURL,
            method: 'post',
            params: params
        })
        .then((res)=>{
            reactiveObj.outputData = res.data.csvData
            if(reactiveObj.outputData) {
                showDefaultModal(`登録処理が完了しました。<br>CSVを出力しますので結果をご確認ください。`)
            }
        })
        .catch((e)=>{
            //console.log('失敗',e)
            showDefaultModal(`登録処理に失敗しました。再度やりなおしてください`)
        })
    }
    //firebaseの更新処理
    const userUpdate = () => {
        //アップロードされたファイルのヘッダーの項目数を判断する（後続処理にて別手段で取得する方向性にする）
        const header = ['LastName1', 'FirstName1', 'LastName2', 'FirstName2', 'Email', 'UserGroup', 'FaceUrl']
        const registrantsId = []
        const addRegistrants = []
        const updateRegistrants = []
        const newUserList = []
        const LatestUpdated = Date.now()
        //既存の社員番号のみを取得
        reactiveObj.registrants.forEach(registrantsElement => {
            registrantsId.push(registrantsElement['Id'])
        })
        reactiveObj.newRegistrants.forEach(newElements => {
            //更新対象が未登録の場合
            if(!registrantsId.includes(newElements['Id'])) {
                addRegistrants.push(newElements)
            }
            //更新対象が登録済の場合
            else {
                reactiveObj.registrants.forEach(registrantsElement => {
                    if(registrantsElement['Id'] == newElements['Id']){
                        let updateFlag = false
                        let changeEmailFlag = false
                        let beforeEmail = ""
                        header.forEach(headerElement => {
                            switch (headerElement) {
                                //値が配列のもの
                                case 'FaceUrl':
                                case 'UserGroup': {
                                    if(JSON.stringify(registrantsElement[headerElement]) != JSON.stringify([newElements[headerElement]])){
                                        registrantsElement[headerElement] = [newElements[headerElement]]
                                        updateFlag = true
                                    }
                                }
                                case 'Email' : {
                                    if(registrantsElement[headerElement] != newElements[headerElement]){
                                        beforeEmail = registrantsElement[headerElement]
                                        registrantsElement[headerElement] = newElements[headerElement]
                                        updateFlag = true
                                        changeEmailFlag = true
                                    }
                                }
                                //それ以外
                                default : {
                                    if(registrantsElement[headerElement] != newElements[headerElement]){
                                        registrantsElement[headerElement] = newElements[headerElement]
                                        updateFlag = true
                                    }
                                }
                            }
                        })
                        if(updateFlag) {
                            newUserList.push({
                                execType: 'edit',
                                userKey: registrantsElement['userId'],
                                username: (newElements['LastName1'] && newElements['FirstName1']) ? newElements['LastName1'] + newElements['FirstName1'] : (newElements['LastName2'] && newElements['FirstName2']) ? newElements['LastName2'] + newElements['FirstName2'] : newElements['Email'],
                                beforeEmail: beforeEmail,
                                afterEmail: newElements['Email']
                            })
                            updateRegistrants.push(registrantsElement)
                        }
                    }
                })
            }
        })
        //登録者情報更新
        if(updateRegistrants.length > 0) {
            updateRegistrants.forEach(updateElement => {
                const userData = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/User/${updateElement['userId']}`);
                const data = {
                    Id: updateElement['Id'],
                    FirstName1: updateElement['FirstName1'],
                    LastName1: updateElement['LastName1'],
                    FirstName2: updateElement['FirstName2'],
                    LastName2: updateElement['LastName2'],
                    Email: updateElement['Email'],
                    UserGroup: updateElement['UserGroup'],
                    FaceUrl: updateElement['FaceUrl'],
                    LatestUpdated: LatestUpdated
                }
                update(userData, data);
            })
        }
        if(addRegistrants.length > 0) {
            //User情報追加
            const userPath = `CorporateId/${reactiveObj.corporateId}/User`
            addRegistrants.forEach(newElements => {
                const newUserKey = push(child(databaseRef(database), userPath)).key;
                newUserList.push({
                    execType: 'regist',
                    userKey:  newUserKey,
                    username: (newElements['LastName1'] && newElements['FirstName1']) ? newElements['LastName1'] + newElements['FirstName1'] : (newElements['LastName2'] && newElements['FirstName2']) ? newElements['LastName2'] + newElements['FirstName2'] : newElements['Email'],
                    beforeEmail: '',
                    afterEmail: newElements['Email']
                })
                const data = {
                    Id: newElements['Id'],
                    FirstName1: newElements['FirstName1'] === undefined ? "" : newElements['FirstName1'],
                    LastName1: newElements['LastName1'] === undefined ? "" : newElements['LastName1'],
                    FirstName2: newElements['FirstName2'] === undefined ? "" : newElements['FirstName2'],
                    LastName2: newElements['LastName2'] === undefined ? "" : newElements['LastName2'],
                    Email: newElements['Email'],
                    UserGroup: newElements['UserGroup'] === undefined ? [""] : [newElements['UserGroup']],
                    FaceUrl: newElements['FaceUrl'] === undefined ? [""] : [newElements['FaceUrl']],
                    LatestUpdated: LatestUpdated
                }
                update(databaseRef(database, `${userPath}/${newUserKey}`), data)
            })
        }
        if(addRegistrants.length > 0 || updateRegistrants.length > 0) {
            //.log(newUserList)
            execFunction(
                "https://asia-northeast1-prooface-smartlock.cloudfunctions.net/modifyUser",
                {
                    newUserList: newUserList,
                    corporateId: reactiveObj.corporateId
                }
            )
        }
        else {
            showDefaultModal(`更新内容がありませんでした`)
        }
    }
    const imageUpdate = () => {
        const uploadPath = `/${reactiveObj.corporateId}/User/`
        reactiveObj.newImages.forEach(async (element) => {
            const metadata = {
                contentType: 'image/jpeg',
            };
            const uploadref = storageRef(storage, `${uploadPath}${element['name']}`)
            //画像をアップロード
            await uploadBytes(uploadref, element, metadata)
        })
    }
    const resetExec = () => {
        reactiveObj.newRegistrants = []
        reactiveObj.newImages.length = []
        const csvLabel = document.getElementById("csvLabel")
        csvLabel.innerHTML = 'CSVファイル参照';
        const imageLabel = document.getElementById("imageLabel")
        imageLabel.innerHTML = '画像ファイル参照';
    }
    const showDefaultModal = (message) => {
        reactiveObj.loadingFlag = false
        //モーダルを表示
        reactiveModalObj.modalFlag= true
        //モーダル上の表示する文面
        reactiveModalObj.modalMessage = message
        //モーダル部分を再描画
        reactiveModalObj.modalRenderkey++
    }
    const closeDefalutModal = (editFlag) => {
        if(reactiveObj.outputData) {
            outputCSVData(reactiveObj.outputData)
        }
        reactiveModalObj.modalFlag= false
        reactiveObj.outputData = ""
    }
    const getAppsetting = () => {
        const settingsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/AppSettings`)
            //firebaseからアプリ情報を取得する
            onValue(settingsRef, (snapshot) => {
                const settings = (snapshot.val())
                reactiveObj.UseNameFlag1 = settings["UseName1"]
                reactiveObj.UseNameFlag2 = settings["UseName2"]
            })
    }
    //Nページ目に表示する利用者情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.registrants.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.registrants.length / perPage) ? Math.ceil(reactiveObj.registrants.length / perPage) : 1
    })
    onMounted(async () => {
        reactiveObj.corporateId = await getCorporateId()
        getRegstrants()
        getAppsetting()
    })
</script>

<template>
    <body>
        <HeaderComponent></HeaderComponent>
        <NavigationBar></NavigationBar>
        <main>
            <div id="loadingDisplay" class="loading" v-show="reactiveObj.loadingFlag">
                <div class="load-text load-blink">処理中...</div>
            </div>
            <div class="userRegistration" :key="`${reactiveObj.renderKey}-userRegistration`">
                <div class="subTitleArea">
                    <p class="subTitle">利用者登録</p>
                </div>
                <div class="userRegist">
                    <label id="csvLabel">CSVファイル参照<input type="file" id="csvButton" @change="fileUpload"></label>
                    <label id="imageLabel">画像ファイル参照<input type="file" id="imageButton" @change="imageUpload" multiple></label>
                    <label id="uploadLabel">アップロード<button type="button" id="uploadButton" @click="uploadExec"></button></label>
                </div>
                <div class="subTitleArea">
                    <p class="subTitle">登録者一覧</p>
                </div>
                <div class="registrants">
                    <table class="commonTable">
                        <tbody>
                            <tr>
                                <th>ID</th>
                                <th v-if="reactiveObj.UseNameFlag1">姓名</th>
                                <th v-if="reactiveObj.UseNameFlag2">フリガナ</th>
                                <th class="leftSideTh">メールアドレス</th>
                                <th>特徴量データ</th>
                            </tr>
                            <tr v-for="(data, key) in getItems" :key="key">
                                <td>{{ data.Id }}</td>
                                <td v-if="reactiveObj.UseNameFlag1">{{ data.LastName1 }} {{ data.FirstName1 }}</td>
                                <td v-if="reactiveObj.UseNameFlag2">{{ data.LastName2 }} {{ data.FirstName2 }}</td>
                                <td class="leftSideTh">{{ data.Email }}</td>
                                <td>{{ data.face }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="paginationArea">
                    <PaginateComponent
                        :page-count="getPageCount"
                        :click-handler="clickCallback"
                        :page-range="5"
                        :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                        :next-text="reactiveObj.currentPage < parseInt(reactiveObj.registrants.length / 10) + 1 ? '>' : ''"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </PaginateComponent>
                </div>
                <DefaultModalComponent
                    :key="reactiveModalObj.modalRenderkey"
                    :modalMessage="reactiveModalObj.modalMessage"
                    v-show="reactiveModalObj.modalFlag"
                    @executeMethod="closeDefalutModal">
                </DefaultModalComponent>
            </div>
        </main>
    </body>
</template>

<style scope>
    .userRegistration {
        padding: 0 5% 0 25%;
        background: #e7e6e6;
    }
    .userRegist {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: space-between;
        gap: 10%;
        background-color: #F5F5F5;
        padding: 0 5% 0 5%;
    }
    .userRegist #csvButton {
        display: none;
    }
    .userRegist #imageButton {
        display: none;
    }
    .userRegist #uploadButton {
        display: none;
    }
    .userRegist #resetButton {
        display: none;
    }
    .userRegist #csvLabel {
        position: relative;
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    .userRegist #imageLabel {
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #d7d7d7;
        background-color: #d7d7d7;
    }
    .userRegist #uploadLabel {
        width: 30%;
        height: 60%;
        font-size: 100%;
        line-height: 55px;
        border-radius: 10px;
        border: solid 3px #7CD5F5;
        background-color: #7CD5F5;
    }
    .userRegist #resetLabel {
        width: 30%;
        font-size: 100%;
        border-radius: 10px;
        border: solid 3px #ed68c1;
        background-color: #edcce2;
    }
</style>
