<script setup>
import { signOut } from "firebase/auth";
import { useRouter } from 'vue-router'
import Firebase from "../firebase_settings/index.js"
const auth = Firebase.auth
const router = useRouter()
const confirmLogout = () => {
    const result = confirm('ログアウトしますか？')
    if(!result) {
        return
    }
    logOut()
}
const logOut = () => {
    // ユーザー情報とイベント内容をログに記録
    signOut(auth).then(() => {
        // Sign-out successful.
        router.push('/login')
        //window.location.reload()
        alert('ログアウトしました')
    }).catch((error) => {
        // An error happened.
        //console.log('ログアウトエラー: error ->'+error)
        alert('ログアウト処理でエラーが発生しました')
    });
}
</script>
<template>
    <!-- 縦型ナビゲーションバー -->
    <div class='navigation_list'>
        <ul>
            <li><router-link class="nav-link" to="/" active-class="link--active">端末状況</router-link></li>
            <li><router-link class="nav-link" to="/userregistration" active-class="link--active">利用者登録</router-link></li>
            <li><router-link class="nav-link" to="/appsettings" active-class="link--active">アプリ設定</router-link></li>
            <li><router-link class="nav-link" to="/entryexitlogs" active-class="link--active">入退室ログ</router-link></li>
            <li><router-link class="nav-link" to="/adminpassword" active-class="link--active">管理パスワード</router-link></li>
            <li><a @click="confirmLogout">ログアウト</a></li>
        </ul>
    </div>
</template>
<script>
</script>

<style>
    .navigation_list{
        margin: 45px 0 0 60px;
        position: absolute;
        width: 15%;
    }
    .navigation_list ul{
        list-style-type: none;
    }
    .navigation_list li:first-child a{
        border:1px solid;
        border-color: #e7e6e6;
        border-radius: 15px 15px 0 0;
    }
    .navigation_list li:last-child a{
        border:1px solid;
        border-color: #e7e6e6;
        border-radius: 0 0 15px 15px;
    }
    .navigation_list li a{
        text-decoration: none;
        font-size: 100%;
        display: block;
        width: 100%;
        height: 100%;
        padding: 10% 10%;
        border:1px solid;
        border-color: #e7e6e6;
        background: #F5F5F5;
        box-sizing: border-box;
    }
    .navigation_list li a:hover {
        background-color: #00AEEB;
        color: #F5F5F5;
    }
    .navigation_list li .link--active {
        background-color: #00AEEB;
        color: #F5F5F5;
    }
</style>
