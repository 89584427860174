<script setup>
    import { computed, reactive, onMounted } from 'vue'
    import HeaderComponent from '../components/HeaderComponent.vue'
    import NavigationBar from '../components/NavigationBar.vue'
    import PaginateComponent from 'vuejs-paginate-next'
    import {formatToTimeZone} from 'date-fns-timezone';
    import {onAuthStateChanged } from "firebase/auth";
    import { ref as databaseRef, onValue } from "firebase/database";
    import Firebase from "../firebase_settings/index.js"
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'

    const DATEFORMAT1 = 'YYYY/MM/DD HH:mm:ss';
    const DATEFORMAT2 = 'YYYYMMDD';
    const DATEFORMAT3 = 'YYYY/MM/DD';
    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
    const auth = Firebase.auth
    const database = Firebase.database;

    const reactiveObj = reactive({
        //企業ID
        corporateId: "",
        //ログ一覧
        logs: [],
        //検索用のログ一覧
        searchLogs: [],
        //再レンダリング用のキー
        renderKey: 0,
        //現在のページ（デフォルトは１ページ）
        currentPage: 1,
        //検索開始日
        fromDate: 0,
        //検索終了日
        toDate: 0,
        //姓名フラグ
        UseNameFlag1: false,
        //セイメイフラグ
        UseNameFlag2: false,
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
    })
    //1ページに表示する件数
    const perPage = 10;

    //firebaseからログ一覧を取得
    const getLogs = () => {
        return new Promise(async (resolve, reject) => {
            if(reactiveObj.corporateId) {
                reactiveObj.logs = [];
                const logsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/UnlockHistory`);
                //firebaseからログを取得する
                const unsubscribe = onValue(logsRef, async (snapshot) => {
                    const logdata = (snapshot.val());
                    for(let userGroup in logdata) {
                        for(let key in logdata[userGroup]) {
                            reactiveObj.logs.push({
                                logGroup: logdata[userGroup],
                                Id: logdata[userGroup][key]['Id'],
                                LatestHistory: logdata[userGroup][key]['LatestHistory'],
                                UserName1: logdata[userGroup][key]['UserName1'],
                                UserName2: logdata[userGroup][key]['UserName2'],
                                TerminalName: logdata[userGroup][key]['TerminalName'],
                                EntryExit: logdata[userGroup][key]['EntryExit'],
                                CodeType: (
                                    logdata[userGroup][key]['CodeType'] == 0 ? '顔認証' :
                                        logdata[userGroup][key]['CodeType'] == 1 ? 'コード認証' :
                                            logdata[userGroup][key]['CodeType'] == 2 ? 'セサミタッチ認証' :
                                                logdata[userGroup][key]['CodeType'] == 3 ? '指紋認証' :
                                                    logdata[userGroup][key]['CodeType'] == 4 ? 'Felica認証' : 'その他'
                                ),
                            })
                        }
                    }

                    //LatestHistory順に並べ替え
                    reactiveObj.logs = await reactiveObj.logs.sort((element, nextElement) => {
                        return (element['LatestHistory'] < nextElement['LatestHistory']) ? -1 : 1;
                    })
                    unsubscribe()
                    return resolve("")
                });
            } else {
                return reject("")
            }
        });
    }
    const searchLog = async() => {
        await getLogs()
        .then(() => {
            reactiveObj.fromDate = new Date(document.getElementById('fromDate').value).getTime() - 32400000
            reactiveObj.toDate = new Date(document.getElementById('toDate').value).getTime() + 53999999
            if(!reactiveObj.fromDate || !reactiveObj.toDate){
                alert('検索したい開始日と終了日を入力してください')
                return
            }
            else if(reactiveObj.fromDate > reactiveObj.toDate){
                alert('開始日と終了日が正しくありません')
                return
            }
            let tmpLog = []
            reactiveObj.logs.forEach(logsElement => {
                if(reactiveObj.fromDate <= logsElement['LatestHistory'] && logsElement['LatestHistory'] <= reactiveObj.toDate){
                    tmpLog.push(logsElement)
                }
            })
            reactiveObj.searchLogs = tmpLog
            reactiveObj.renderKey++
            })
        .catch(() => {
            alert('データの読み込みに失敗しました。再度時間を置いて行ってください')
        })
    }
    //ページネーションを押下した時
    const clickCallback = (pageNum) => {
        reactiveObj.currentPage = Number(pageNum);
    }
    //Nページ目に表示するドア情報を取得
    const getItems = computed(() => {
        let current = reactiveObj.currentPage * perPage;
        let start = current - perPage;
        return reactiveObj.searchLogs.slice(start, current);
    })
    //現在のページ数を取得
    const getPageCount = computed(() => {
        return Math.ceil(reactiveObj.searchLogs.length / perPage) ? Math.ceil(reactiveObj.searchLogs.length / perPage) : 1
    })
    const showDefalutModal = () => {
        if(reactiveObj.searchLogs.length == 0){
                alert('出力したいログを検索より指定してから実行してください')
                return
        }
        else {
            const searchFrom = formatToTimeZone(reactiveObj.fromDate, DATEFORMAT3, { timeZone: TIME_ZONE_TOKYO })
            const searchTo = formatToTimeZone(reactiveObj.toDate, DATEFORMAT3, { timeZone: TIME_ZONE_TOKYO })
            //モーダルを表示
            reactiveModalObj.modalFlag= true
            //モーダル上の表示する文面
            reactiveModalObj.modalMessage = `${searchFrom}～${searchTo}<br>のログを出力します。<br><br>よろしいですか？`
            //モーダル部分を再描画
            reactiveModalObj.modalRenderkey++
        }
    }
    const closeDefalutModal = (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag) {
            const nameFrom = formatToTimeZone(reactiveObj.fromDate, DATEFORMAT2, { timeZone: TIME_ZONE_TOKYO })
            const nameTo = formatToTimeZone(reactiveObj.toDate, DATEFORMAT2, { timeZone: TIME_ZONE_TOKYO })
            let csvContent = '\ufeff' + `日時,ID${reactiveObj.UseName1 ? ",利用者" : ""}${reactiveObj.UseName2 ? ",利用者(セイメイ)" : ""},端末名,入退室,解錠種別\n`
            //要修正
            reactiveObj.searchLogs.forEach(logsElement => {
                let date = formatToTimeZone(logsElement['LatestHistory'], DATEFORMAT1, { timeZone: TIME_ZONE_TOKYO })
                let UserName1 = reactiveObj.UseName1 ? `,${logsElement['UserName1']}` : ""
                let UserName2 = reactiveObj.UseName2 ? `,${logsElement['UserName2']}` : ""
                let line = `${date},${logsElement['Id']}${UserName1}${UserName2},${logsElement['TerminalName']},${logsElement['EntryExit']},${logsElement['CodeType']}\n`
                csvContent += line
            })
            let blob = new Blob([csvContent], { type: 'text/csv' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${nameFrom}-${nameTo}_logfile.csv`
            link.click()
        }
    }
    const getCorporateId = () => {
        return new Promise((resolve) => {
            //ログインチェック
            onAuthStateChanged(auth, (user) => {
                const getData = databaseRef(database, `LoginUser/${user.uid}`);
                onValue(getData, (snapshot) => {
                    resolve(snapshot.val()["CorporateId"] ? snapshot.val()["CorporateId"] : "");
                })
            })
		})
    }
    const getAppsetting = () => {
        const settingsRef = databaseRef(database, `CorporateId/${reactiveObj.corporateId}/AppSettings`)
            //firebaseからアプリ情報を取得する
            onValue(settingsRef, (snapshot) => {
                const settings = (snapshot.val())
                reactiveObj.UseNameFlag1 = settings["UseName1"]
                reactiveObj.UseNameFlag2 = settings["UseName2"]
            })
    }
    onMounted(async () => {
        reactiveObj.corporateId = await getCorporateId()
        getAppsetting()
    })
</script>

<template>
    <body>
        <HeaderComponent></HeaderComponent>
        <NavigationBar></NavigationBar>
        <main>
            <div class="entryExitLogs" :key="`${reactiveObj.renderKey}-entryExitLogs`">
                <div class="subTitleArea">
                    <p class="subTitle">入退室ログ</p>
                </div>
                <div class="entryExit">
                    <p>期間指定</p>
                    <input type="date" id="fromDate">
                    <p>～</p>
                    <input type="date" id="toDate">
                    <button id="searchButton" @click="searchLog()">検索</button>
                </div>
                <div class="subTitleArea">
                    <p class="subTitle">登録者一覧</p>
                </div>
                <div class="logsArea">
                    <table class="commonTable">
                        <tbody>
                            <tr>
                                <th class="leftSideTh">日時</th>
                                <th>ID</th>
                                <th v-if="reactiveObj.UseNameFlag1">利用者</th>
                                <th v-if="reactiveObj.UseNameFlag2">利用者(フリガナ)</th>
                                <th>端末名</th>
                                <th>入退室</th>
                                <th>解錠種別</th>
                            </tr>
                            <tr v-for="(data, key) in getItems" :key="key">
                                <td class="leftSideTh">{{ formatToTimeZone(data.LatestHistory, DATEFORMAT1, { timeZone: TIME_ZONE_TOKYO }) }}</td>
                                <td>{{ data.Id }}</td>
                                <td v-if="reactiveObj.UseNameFlag1">{{ data.UserName1 }}</td>
                                <td v-if="reactiveObj.UseNameFlag2">{{ data.UserName2 }}</td>
                                <td>{{ data.TerminalName }}</td>
                                <td>{{ data.EntryExit }}</td>
                                <td>{{ data.CodeType }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="paginationArea">
                    <PaginateComponent
                        :page-count="getPageCount"
                        :click-handler="clickCallback"
                        :page-range="5"
                        :prev-text="reactiveObj.currentPage >= 2 ? '<' : ''"
                        :next-text="reactiveObj.currentPage < parseInt(reactiveObj.searchLogs.length / 10) + 1 ? '>' : ''"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </PaginateComponent>
                    <div class="downloadArea">
                        <p>CSVダウンロード　</p>
                        <button id="csvDownload" @click="showDefalutModal()">ダウンロード</button>
                    </div>
                </div>
                <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefalutModal"></DefaultModalComponent>
            </div>
        </main>
    </body>
</template>

<style scope>
    .entryExitLogs {
        padding: 0 5% 0 25%;
        background: #e7e6e6;
    }
    .titleArea {
        height: 90px;
    }
    .entryExit {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: space-between;
        gap: 5%;
        background-color: #F5F5F5;
        padding: 0 5% 0 5%;
    }
    .entryExit #fromDate {
        width: 25%;
        border-radius: 10px;
        text-align: center;
        background-color: #EEEEEF;
    }
    .entryExit #toDate {
        width: 25%;
        border-radius: 10px;
        text-align: center;
        background-color: #EEEEEF;
    }
    .entryExit #searchButton {
        border: none;
        background-color: #7CD5F5;
    }
    .downloadArea {
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 0 10% 0 0;
    }
    .downloadArea #csvDownload {
        border: none;
        background-color: #7CD5F5;
    }
</style>
