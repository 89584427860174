// 以下はFireBaseSDK
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBYkYVHxB1-0hSdUumTUTJr-5_agBIJ7R4",
    authDomain: "prooface-smartlock.firebaseapp.com",
    projectId: "prooface-smartlock",
    storageBucket: "prooface-smartlock.appspot.com",
    messagingSenderId: "391588737786",
    appId: "1:391588737786:web:3aa090b7165382f8310f43",
    measurementId: "G-V62E8JC68N",
    databaseURL: "https://prooface-smartlock-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseapp);
const database = getDatabase(firebaseapp);
const auth = getAuth(firebaseapp);
const storage = getStorage(firebaseapp)

export default { analytics, database, auth, storage };
