<script setup>
    import { reactive, watch } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
    import Firebase from "../firebase_settings/index.js"
    import DefaultModalComponent from '../components/DefaultModalComponent.vue'
    import axios from 'axios';

    const route = useRoute();
    const router = useRouter();

    const auth = Firebase.auth
    const reactiveObj = reactive({
        //新しいパスワード
        newPassword1: "",
        //新しいパスワード（確認）
        newPassword2: "",
    })
    const reactiveModalObj = reactive({
        //モーダル開閉
        modalFlag: false,
        //モーダルに表示するメッセージ
        modalMessage: "",
        //モーダルの再描画用のキー
        modalRenderkey: 0,
        //
        loadingFlag: false
    })

    const inputSomeData = () => {
        let equalFlag = false
        if(reactiveObj.newPassword1 && reactiveObj.newPassword2){
                equalFlag = true
        }
        equalFlag? document.getElementById('commonButton').disabled = false: document.getElementById('commonButton').disabled = true
    }
    const changePassword = () => {
        if(reactiveObj.newPassword1 === reactiveObj.newPassword2){
            reactiveModalObj.loadingFlag = true
            const oobCode = route.query.oobCode
            const mode = route.query.mode
            const continueUrl = route.query.continueUrl
            if(mode == "resetPassword"){
                verifyPasswordResetCode(auth, oobCode)
                .then((email) => {
                    confirmPasswordReset(auth, oobCode, reactiveObj.newPassword1)
                    .then(async (resp) => {
                        await pushEmail(email)
                        reactiveModalObj.loadingFlag = false
                        showDefalutModal()
                    })
                    .catch((error) => {
                        //console.log(error)
                    });
                }).catch((error) => {
                    reactiveModalObj.loadingFlag = true
                    alert('oobCodeが正しくありません正規の手順で再度実行をしてください')
                    //console.log(error)
                });
            }
        }
        else {
            alert('入力された新しいパスワードが一致しません')
        }
    }
    const pushEmail = (email) => {
        return new Promise((resolve) => {
            axios({
                baseURL: "https://asia-northeast1-prooface-smartlock.cloudfunctions.net/pushMail",
                method: 'post',
                params:{
                    email: email,
                }
            })
            .then((res)=>{
                //console.log(res)
                resolve(true)
            })
            .catch((e)=>{
                //resolve(false)
            })
        })
    }
    const showDefalutModal = () => {
        //モーダルを表示
        reactiveModalObj.modalFlag= true
        //モーダル上の表示する文面
        reactiveModalObj.modalMessage = `パスワードを変更しました。`
        //モーダル部分を再描画
        reactiveModalObj.modalRenderkey++
    }
    const closeDefalutModal = (editFlag) => {
        reactiveModalObj.modalFlag= false
        if(editFlag) {
            router.push({
                name: 'userpassword',
            });
        }
    }
    watch(reactiveObj, () => {
        inputSomeData()
    })
</script>

<template>
    <body>
        <main>
            <link rel="stylesheet" href="./togglePassword.css">
            <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
            <div id="loadingDisplay" class="loading" v-show="reactiveModalObj.loadingFlag">
                <div class="load-text load-blink">処理中...</div>
            </div>
            <div class="userPassword">
                <p class="title">利用者パスワード再設定</p>
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　新しいパスワード</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.newPassword1">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　新しいパスワード（確認）</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.newPassword2">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.password">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="updateArea"><button type="button" id="commonButton" @click="changePassword()" disabled>設定する</button></td>
                        </tr>
                    </tbody>
                </table>
                <DefaultModalComponent :key="reactiveModalObj.modalRenderkey" :modalMessage="reactiveModalObj.modalMessage" v-show="reactiveModalObj.modalFlag" @executeMethod="closeDefalutModal"></DefaultModalComponent>
            </div>
        </main>
    </body>
</template>

<style scoped>
    .userPassword{
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child{
        border-radius: 20px 20px 0 0;
    }
</style>
