import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AgreeView from '../views/AgreeView.vue'
import TerminalStatusView from '../views/TerminalStatusView.vue'
import AdminPasswordView from '../views/AdminPasswordView.vue'
import AppSettingsView from '../views/AppSettingsView.vue'
import EntryExitLogsView from '../views/EntryExitLogsView.vue'
import UserRegistrationView from '../views/UserRegistrationView.vue'
import UserPasswordView from '../views/UserPasswordView.vue'
import UserPasswordResetView from '../views/UserPasswordResetView.vue'
import UserPasswordSetView from '../views/UserPasswordSetView.vue'

// 画面遷移前にログイン済みかを判定するメソッドに必要なFirebaseのメソッド
import { getAuth } from "firebase/auth";
import { ref as databaseRef, onValue, query } from "firebase/database";
import Firebase from "../firebase_settings/index.js"

const database = Firebase.database;
const auth = getAuth()

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: { title: 'Login', requiresAuth: false, agreeAuth: false}
    },
    {
        path: '/agree',
        name: 'agree',
        component: AgreeView,
        meta: { title: 'Agree', requiresAuth: true, agreeAuth: false}
    },
    {
        path: '/',
        name: 'terminalstatus',
        component: TerminalStatusView,
        meta: { title: 'terminalstatus', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/adminpassword',
        name: 'adminpassword',
        component: AdminPasswordView,
        meta: { title: 'adminpassword', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/appsettings',
        name: 'appsettings',
        component: AppSettingsView,
        meta: { title: 'appsettings', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/entryexitlogs',
        name: 'entryexitlogs',
        component: EntryExitLogsView,
        meta: { title: 'entryexitlogs', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/userregistration',
        name: 'userregistration',
        component: UserRegistrationView,
        meta: { title: 'userregistration', requiresAuth: true, agreeAuth: true}
    },
    {
        path: '/userpassword/',
        name: 'userpassword',
        component: UserPasswordView,
        meta: { title: 'userpassword'}
    },
    {
        path: '/userpasswordreset/',
        name: 'userpasswordreset',
        component: UserPasswordResetView,
        meta: { title: 'userpasswordreset'}
    },
    {
        path: '/userpasswordset/',
        name: 'userpasswordset',
        component: UserPasswordSetView,
        meta: { title: 'userpasswordset'}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 画面遷移成功後にページタイトルを設定
router.afterEach((titleString) => {
    document.title = titleString.meta.title + ' | スマートロック管理画面'
});

function isAuthenticated() {
    return new Promise((resolve) => {
        const unsubscribe = getAuth().onAuthStateChanged((user) => {
            unsubscribe();
            resolve(user ? true : false);
        })
    })
}

function checkAddFlag() {
    const getData = databaseRef(database, `LoginUser/${auth.currentUser.uid}/Agree`);
    return new Promise((resolve) => {
        onValue(getData, (snapshot) => {
            resolve(snapshot.val() ? true : false);
        })
    });
}

// 画面遷移前にログイン済みかをチェックして、未ログイン時はログイン画面に強制遷移させる
router.beforeEach(async(to, from) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const agreeAuth = to.matched.some((record) => record.meta.agreeAuth);
    if(agreeAuth && await isAuthenticated() && !(await checkAddFlag())){
        return '/agree'
    } else if(requiresAuth && !(await isAuthenticated())){
        return '/login'
    }
})
export default router
