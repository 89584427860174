<script setup>
    import { watch, reactive } from 'vue'
    const emit = defineEmits(['executeMethod']);
    const reactiveObj = reactive({
        //ドア（端末名）
        terminal: '',
        //設置先
        location: '',
        //親機/子機/単独
        terminalType: '',
        //施錠までの時間
        locktime: '',
    })
    const valueReset = () => {
        reactiveObj.terminal = ''
        reactiveObj.location = ''
        reactiveObj.terminalType = ''
        reactiveObj.locktime = ''
    }
    //施錠時間のマッピング
    const exitModal = (editFlag) => {
        emit('executeMethod', editFlag, reactiveObj);
        document.getElementById('commonButton').disabled = true;
        valueReset()
    }
    watch(reactiveObj, () => {
        if(reactiveObj.terminal && reactiveObj.location && reactiveObj.terminalType && reactiveObj.locktime){
            document.getElementById('commonButton').disabled = false;
        }
    })
</script>

<template>
    <div class="modalArea">
        <div id="modalContent" class="modal">
            <p class="modalTitle">端末情報の追加</p>
            <div class="modalInfo">
                <p>端末名</p>
                <input class="modalTerminal" v-model="reactiveObj.terminal">
            </div>
            <div class="modalInfo">
                <p>設置先</p>
                <select class="modalLocation" v-model="reactiveObj.location">
                    <option value="外側">外側</option>
                    <option value="内側">内側</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>親子設定</p>
                <select class="modalTerminalType" v-model="reactiveObj.terminalType">
                    <option value="parent">親機</option>
                    <option value="single">単独</option>
                </select>
            </div>
            <div class="modalInfo">
                <p>施錠までの時間</p>
                <select class="modalLocktime" v-model="reactiveObj.locktime">
                    <option value="5">5秒</option>
                    <option value="7">7秒</option>
                    <option value="10">10秒</option>
                    <option value="15">15秒</option>
                    <option value="20">20秒</option>
                    <option value="30">30秒</option>
                    <option value="0">自動で施錠しない</option>
                    <option value="-1">オープンセンサーを使用</option>
                </select>
            </div>
            <button id="commonButton" @click="exitModal(true)" disabled>追加する</button>
        </div>
        <div class="modalOverlay" @click.self="exitModal(false)"></div>
    </div>
</template>

<style scoped>
    .modal {
        width: 60%;
        height: 70%;
        top: 50%;
        left: 50%;
    }
    #commonButton {
        width: 30%;
        height: 15%;
        top: 10%;
        left: 50%;
    }
</style>