<script setup>
    import { reactive, watch } from 'vue'
    import { useRoute, useRouter, parseQuery } from 'vue-router'
    import LoadingAnimationComponent from '../components/LoadingAnimationComponent.vue'
    import axios from 'axios';

    const route = useRoute()
    const router = useRouter()
    const reactiveObj = reactive({
        //firebaseUUID
        uuid: "",
        //ローディングアニメーション
        isLoading: false
    })
    const getLink = () => {
        return new Promise((resolve) => {
			axios({
                baseURL: "https://asia-northeast1-prooface-smartlock.cloudfunctions.net/userPasswordReset",
                method: 'post',
                params:{
                    uuid: reactiveObj.uuid,
                    corporateId: route.query.CorporateId
                }
            })
            .then((res)=>{
                //console.log()
                resolve({
                    name: 'userpasswordset',
                    query: parseQuery(res.data)
                })
            })
            .catch((error)=>{
                //console.log('失敗',error)
                alert(error.response.data.errorMessaage)
                resolve({
                    name: 'userpasswordreset',
                    query: {
                        CorporateId: route.query.CorporateId
                    }
                })
            })
        })
    }
    const checkUUid = async () => {
        reactiveObj.isLoading = true
        try {
            router.push(await getLink())
        } catch(error) {
            alert('登録されている企業コードとUIDを確認してください')
        } finally {
            reactiveObj.isLoading = false
        }
    }
    watch(reactiveObj, () => {
        if(reactiveObj.uuid){
            document.getElementById('commonButton').disabled = false;
        }
    })
</script>

<template>
    <body>
        <main>
            <link rel="stylesheet" href="./togglePassword.css">
            <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
            <div class="userPassword">
                <p class="title">利用者のパスワードリセット</p>
                <table class="commonTable">
                    <tbody>
                        <tr>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="firstTd notButtom">　ユーザー識別コード（UID）</td>
                        </tr>
                        <tr>
                            <td class="firstTd notTop">
                                <input type="checkbox" class="checkPassword">
                                <div class="togglePassword">
                                    <input type="password" class="hideText" v-model="reactiveObj.uuid">
                                    <!--<input type="text" class="showText" v-model="reactiveObj.uuid">-->
                                    <!--<label for="checkPassword" class="fa fa-eye"></label>-->
                                    <!--<label for="checkPassword" class="fa fa-eye-slash"></label>-->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="updateArea">
                                <button type="button" id="commonButton" @click="checkUUid()" disabled="true">次へ</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="loadingAnimationContainer">
                    <div class="loadingAnimation" v-if="reactiveObj.isLoading">
                        <LoadingAnimationComponent></LoadingAnimationComponent>
                    </div>
                </div>
            </div>
        </main>
    </body>
</template>

<style scoped>
    .userPassword{
        padding: 0 20% 0 20%;
        background-color: #e7e6e6;
    }
    .commonTable tr:first-child>*:last-child{
        border-radius: 20px 20px 0 0;
    }
    .loadingAnimationContainer {
        height: 15px;
        margin-top: 5%;
    }
</style>
