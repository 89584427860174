<template>
    <div class="header_container">
        <p id="corporateName">ProoFace</p>
    </div>
</template>

<style scoped>
    @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap");
    @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
    header {
        background-color: #e7e6e6;
        width: auto;
    }
    .header_container {
        display: flex;
        justify-content: space-between;
        padding: 30px 0 0 50px;
        height: 60px;
        width: auto;
        background-color: #e7e6e6;
    }
    .header_container p {
        font-size: 35px;
        padding: 0 0 0 10px;
    }
</style>